export const statusData = [
  { color:'blue', title:'Yeni', strap:'primary' },
  { color:'purple', title:'Ödendi' },
  { color:'#00748E', title:'Hazırlanıyor' },
  { color:'green', title:'Hazırlandı' },
  { color:'orange', title:'Kargoya Verilecek' },
  { color:'teal', title:'Kargoya Verildi' },
  { color:'pink', title:' Teslim Edildi' },
  { color:'red', title:'Listeden Kaldır' }
]
