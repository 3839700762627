import { getLive, getSync } from '../utils/Storken'

export const GA_TRACKING_ID = 'GTM-K6S2ZLS'

export const pageview = (url) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url
  })
}
const getItems = itemArr => itemArr.map(({ _id, name, category, packsize, quantity }) => ({
  id:_id,
  name,
  list_name: getLive('categories')?.find(c => c?._id === category)?.name,
  brand:'Köyden Gelsin',
  category: getLive('categories')?.find(c => c?._id === category)?.name,
  variant: packsize?.size,
  list_position: getSync('basket')?.findIndex(p => p?._id === _id),
  quantity,
  price: packsize?.price
}))

export const eventExtra = (action, itemArr, opt, cost) => {
  window.gtag('event', action, {
    currency: 'TRY',
    items: getItems(itemArr),
    value: cost,
    content_type: opt,
    checkout_step:opt
  })
}
export const event = (action, itemArr, cost) => {
  window.gtag('event', action, {
    currency: 'TRY',
    items: getItems(itemArr),
    value: cost
  })
}
