import { getLive } from '../utils/Storken'

export const pageview = () => {
  window.fbq('track', 'PageView')
}

export const pixelEvent = (event, content = {}) => {
  window.fbq('track', event, getProduct(content))
}
export const customPixelEvent = (event, content = [], cost) => {
  window.fbq('track', event, getMultipleProducts(content, cost))
}

const getMultipleProducts = (data, cost) => ({
  content_type:'product',
  content_ids:data?.map(({ _id }) => _id),
  contents:data?.map(({ _id, quantity }) => ({ id:_id, quantity })),
  currency:'TRY',
  num_items:data?.length,
  value:cost
})
const getProduct = ({ _id, name, category, packsize, quantity }) => ({
  content_name: name,
  content_category: getLive('categories')?.find(c => c?._id === category)?.name,
  content_ids: [_id],
  content_type: 'product',
  value: packsize?.price,
  currency: 'TRY',
  contents:[{ id:_id, quantity }]
})
