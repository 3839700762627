// eslint-disable-next-line import/no-named-as-default
import api from './_http'
import redirect from '../utils/redirect'

const { window } = global
export const signUpWithUser = data => api.post('/signup', data)
export const getCurrentUser = () => api.get('/isAuthenticated').then(resp => (resp.data.user))

export const signOutWithUser = () => api.get('/signout').then((resp) => {
  if (!resp.data.user) {
    redirect(window, '/')
  }
})
export const signInWithUser = data => api.post('/signin', data).then((resp) => {
  if (resp.data.user) {
    redirect(window, '/dashboard')
  }
})
export const goToPage = page => redirect(window, page)

export const checkLoggedIn = () => api.get('/isAuthenticated').then((resp) => {
  if (!resp?.data?.auth) {
    redirect(window, '/')
  }
})


export const UserActions = {
  signUpWithUser,
  signInWithUser,
  signOutWithUser,
  checkLoggedIn,
  getCurrentUser
}
